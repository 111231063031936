import { createMuiTheme } from "@material-ui/core/styles"

const resolutions = {
  lg: 1280,
  md: 960,
  sm: 600,
  xl: 1920,
  xs: 0,
}

const theme = createMuiTheme({
  palette: {
    text: {
      primary: "#4d4d4d",
      secondary: "#1a1a1a",
      grey: "#ccc",
      link: "#6805a6",
      linkHover: "#8807D9",
      dark: "#100a23",
      gray95: "#f2f2f2",
    },
    background: {
      saas: "#4a166b",
    },
  },
  fadeIn: {
    "@keyframes fadein": {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
  },
  defaultContainer: {
    padding: "36px 122px",
    [`@media (max-width: ${resolutions.lg}px)`]: {
      padding: "10px 33px",
    },
    [`@media (max-width: ${resolutions.sm}px)`]: {
      padding: "10px 20px",
    },
    [`@media (max-width: ${resolutions.xs}px)`]: {
      padding: "20px",
    },
  },
  defaultHorizonContainer: {
    paddingLeft: "135px",
    paddingRight: "135px",
    [`@media (max-width: ${resolutions.lg}px)`]: {
      paddingLeft: "33px",
      paddingRight: "33px",
    },
    [`@media (max-width: ${resolutions.sm}px)`]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  typography: {
    useNextVariants: true,
    "-webkit-font-smoothing": "antialiased",
    h1: {
      color: "#4d4d4d",
      fontSize: "54px",
      fontFamily: "Europa, sans-serif",
      textDecoration: "none",
      fontWeight: "800",
      lineHeight: "1.02",
      letterSpacing: "-1px",
      backgroundColor: "transparent",
      wordBreak: "break-word",
      "-webkit-font-smoothing": "antialiased",
      [`@media (max-width: ${resolutions.sm}px)`]: {
        fontSize: "42px",
      }
    },
    h2: {
      color: "#4a166b",
      fontSize: "40px",
      fontFamily: "Europa, sans-serif",
      textDecoration: "none",
      fontWeight: "800",
      backgroundColor: "transparent",
      wordBreak: "break-word",
      "-webkit-font-smoothing": "antialiased",
      lineHeight: "1.13",
      letterSpacing: "-0.74px",
    },
    h3: {
      color: "#fff",
      fontSize: "40px",
      fontFamily: "Europa, sans-serif",
      textDecoration: "none",
      fontWeight: "800",
      lineHeight: "1.2",
      letterSpacing: "0em",
      backgroundColor: "transparent",
      wordBreak: "break-word",
      "-webkit-font-smoothing": "antialiased",
    },
    h4: {
      fontSize: "24px",
      fontFamily: "Europa, sans-serif",
      textDecoration: "none",
      fontWeight: "300",
      backgroundColor: "transparent",
      wordBreak: "break-word",
      "-webkit-font-smoothing": "antialiased",
      lineHeight: "1.58",
      letterSpacing: "-0.6px",
      color: "#4d4d4d",
    },
    h5: {
      fontSize: "20px",
      fontFamily: "Europa, sans-serif",
      textDecoration: "none",
      fontWeight: "800",
      backgroundColor: "transparent",
      wordBreak: "break-word",
      "-webkit-font-smoothing": "antialiased",
      letterSpacing: "0.5px",
      color: "#4d4d4d",
    },
    h6: {
      color: "#fff",
      fontSize: "15px",
      fontFamily: "Europa, sans-serif",
      textDecoration: "none",
      fontWeight: "400",
      backgroundColor: "transparent",
      wordBreak: "break-word",
      "-webkit-font-smoothing": "antialiased",
    },
    body1: {
      fontFamily: "Europa, sans-serif",
      fontSize: "15px",
      color: "#4d4d4d",
      wordBreak: "break-word",
      "-webkit-font-smoothing": "antialiased",
    },
  },
  overrides: {
    MuiButton: {
      label: {
        fontFamily: "Europa, sans-serif",
      },
      text: {
        padding: "0 40px",
        color: "#fff",
        fontFamily: "Europa, sans-serif",
        textTransform: "none",
        height: 50,
        fontSize: "18px",
        letterSpacing: "-0.1px",
        fontWeight: 600,
        borderRadius: "10px",
        cursor: "pointer",
        outline: "none",
        lineHeight: "1",
        backgroundImage: "linear-gradient(to bottom, #ffb533, #ffa600)",
        boxShadow: "0 10px 20px 0 rgba(0, 0, 0, 0.1)",
        "-webkit-transition": "all 250ms linear",
        "&:hover": {
          backgroundImage: "linear-gradient(to bottom, #FFC533, #FFBB00)",
          boxShadow: "0 10px 20px 0 rgba(0, 0, 0, 0.2)",
        },
        [`@media (max-width: ${resolutions.sm}px)`]: {
          padding: "0 26px",
        },
      },
      outlined: {
        padding: "0 40px",
        color: "#4d4d4d",
        fontFamily: "Europa, sans-serif",
        textTransform: "none",
        height: 50,
        fontSize: "18px",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1",
        fontWeight: 600,
        borderRadius: "10px",
        border: "solid 2px #ff9a2e",
        cursor: "pointer",
        outline: "none",
        background: "#f2f2f2",
        transition: "0.3s",
        [`@media (max-width: ${resolutions.sm}px)`]: {
          padding: "0 26px",
        },
      },
    },
  },
  spacing: 5
})


export { theme as default }